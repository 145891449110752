<template>
<div id="pageTable">
    <v-container grid-list-xl fluid>
        <v-card>
            <v-layout class="d-flex flex-lg-row flex-md-column flex-sm-column" wrap pa-3>                
                <!-- <v-flex lg12 md12 sm12 xs12>
                    <v-select label="Loại đơn hàng" v-model="selectedItem.OrderType" :items="order_type_list" item-text="name" item-value="value"></v-select>
                </v-flex> -->
                <v-flex lg12 md12 sm12 xs12>
                    <v-text-field v-model="selectedItem.PackageName" label="Tên gói bảo hiểm" class="input-group--focused"></v-text-field>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <h-currency-input v-model="selectedItem.PercentFee" :decimal="2" label="Phí bảo hiểm (%)" class="input-group--focused"></h-currency-input>
                </v-flex>
                <v-flex lg12 md12 sm12 xs12>
                    <v-textarea v-model="selectedItem.Note" label="Ghi chú" class="input-group--focused"></v-textarea>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</div>
</template>

<script>
import {
    mapState
} from "vuex";
import configs from "../../configs";
export default {
    data() {
        return {
            order_type_list: configs.ORDER_TYPE_LIST,
        }
    },
    computed: {
        ...mapState({
            loading: state => state.feeGuarantee.loading,
            selectedItem: state => state.feeGuarantee.selected
        }),
        modifyId() {
            return this.$route.params.Pid;
        }
    },
    watch: {
        modifyId() {
            this.refreshData();
        }
    },
    methods: {
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("feeGuarantee/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("feeGuarantee/setDetail", { data: {
                        ID: 0,
                        OrderType: 0,
                        PackageName: '',
                        PercentFee: 0
                    }
                })
            }
        },
        saveData() {
            this.$store.dispatch("feeGuarantee/setDetail", {
                data: {
                    ...this.selectedItem,
                    ModifiedBy: this.$store.state.authentication.user.username
                },
                id: this.selectedItem.ID
            });
            window.getApp.$emit("APP_NAVIGATE", "FeeGuaranteeList");
        },
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>